/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import {ColorPaletteProp} from '@mui/joy/styles';

import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Checkbox from '@mui/joy/Checkbox';
import IconButton, {iconButtonClasses} from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Dropdown from '@mui/joy/Dropdown';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';

import {useNavigate} from "react-router";
import {InterfacePost} from "../../interface/InterfacePost";
import moment from "moment";
import {getFormatedDate} from "../../Utils/date";
import {ChipByStatePost} from "../Chip/ChipStatePost/ChipStatePost";


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


interface PropsRowMenu {
    post: InterfacePost,
}

const RowMenu: React.FC<PropsRowMenu> = ({post}) => {
    const navigate = useNavigate();
    return (
        <Dropdown>
            <MenuButton
                slots={{root: IconButton}}
                slotProps={{root: {variant: 'plain', color: 'neutral', size: 'sm'}}}
            >
                <MoreHorizRoundedIcon/>
            </MenuButton>
            <Menu size="sm" sx={{minWidth: 140}}>
                <MenuItem onClick={() => navigate('/admin/article/show/' + post._id)}>
                    Voir l'article
                </MenuItem>
                <Divider/>
                <MenuItem color="danger">Delete</MenuItem>
            </Menu>
        </Dropdown>
    );
}


interface Props {
    items: InterfacePost[]
}

const PostTable: React.FC<Props> = ({items}) => {

    const [order, setOrder] = React.useState<Order>('desc');
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [open, setOpen] = React.useState(false);
    const renderFilters = () => (
        <React.Fragment>
            <FormControl size="sm">
                <FormLabel>État</FormLabel>
                <Select
                    size="sm"
                    placeholder="Filtre par état"
                    slotProps={{button: {sx: {whiteSpace: 'nowrap'}}}}
                >
                    <Option value="paid">Brouillon</Option>
                    <Option value="pending">Non publié</Option>
                    <Option value="refunded">Publié</Option>
                </Select>
            </FormControl>
        </React.Fragment>
    );
    return (
        <React.Fragment>
            <Sheet
                className="SearchAndFilters-mobile"
                sx={{
                    display: {xs: 'flex', sm: 'none'},
                    my: 1,
                    gap: 1,
                }}
            >
                <Input
                    size="sm"
                    placeholder="Search"
                    startDecorator={<SearchIcon/>}
                    sx={{flexGrow: 1}}
                />
                <IconButton
                    size="sm"
                    variant="outlined"
                    color="neutral"
                    onClick={() => setOpen(true)}
                >
                    <FilterAltIcon/>
                </IconButton>
                <Modal open={open} onClose={() => setOpen(false)}>
                    <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
                        <ModalClose/>
                        <Typography id="filter-modal" level="h2">
                            Filters
                        </Typography>
                        <Divider sx={{my: 2}}/>
                        <Sheet sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                            {renderFilters()}
                            <Button color="primary" onClick={() => setOpen(false)}>
                                Submit
                            </Button>
                        </Sheet>
                    </ModalDialog>
                </Modal>
            </Sheet>
            <Box
                className="SearchAndFilters-tabletUp"
                sx={{
                    borderRadius: 'sm',
                    py: 2,
                    display: {xs: 'none', sm: 'flex'},
                    flexWrap: 'wrap',
                    gap: 1.5,
                    '& > *': {
                        minWidth: {xs: '120px', md: '160px'},
                    },
                }}
            >
                <FormControl sx={{flex: 1}} size="sm">
                    <FormLabel>Recherche par titre</FormLabel>
                    <Input size="sm" placeholder="Recherche" startDecorator={<SearchIcon/>}/>
                </FormControl>
                {renderFilters()}
            </Box>
            <Sheet
                className="OrderTableContainer"
                variant="outlined"
                sx={{
                    display: {xs: 'none', sm: 'initial'},
                    width: '100%',
                    borderRadius: 'sm',
                    flexShrink: 1,
                    overflow: 'auto',
                    minHeight: 0,
                }}
            >
                <Table
                    aria-labelledby="tableTitle"
                    stickyHeader
                    hoverRow
                    sx={{
                        '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                        '--Table-headerUnderlineThickness': '1px',
                        '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                        '--TableCell-paddingY': '4px',
                        '--TableCell-paddingX': '8px',
                    }}
                >
                    <thead>
                    <tr>
                        <th style={{width: 48, textAlign: 'center', padding: '12px 6px'}}>
                            <Checkbox
                                size="sm"
                                indeterminate={
                                    selected.length > 0 && selected.length !== items.length
                                }
                                checked={selected.length === items.length}
                                onChange={(event) => {
                                    setSelected(
                                        event.target.checked ? items.map((item) => item._id) : [],
                                    );
                                }}
                                color={
                                    selected.length > 0 || selected.length === items.length
                                        ? 'primary'
                                        : undefined
                                }
                                sx={{verticalAlign: 'text-bottom'}}
                            />
                        </th>
                        <th style={{width: 120, padding: '12px 6px'}}>
                            <Link
                                underline="none"
                                color="primary"
                                component="button"
                                onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
                                fontWeight="lg"
                                endDecorator={<ArrowDropDownIcon/>}
                                sx={{
                                    '& svg': {
                                        transition: '0.2s',
                                        transform:
                                            order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                                    },
                                }}
                            >
                                Titre
                            </Link>
                        </th>
                        <th style={{width: 140, padding: '12px 6px'}}>Date</th>
                        <th style={{width: 140, padding: '12px 6px'}}>Status</th>
                        <th style={{width: 140, padding: '12px 6px'}}></th>
                    </tr>
                    </thead>
                    <tbody>
                    {items.map((row) => (
                        <tr key={row._id}>
                            <td style={{textAlign: 'center', width: 120}}>
                                <Checkbox
                                    size="sm"
                                    checked={selected.includes(row._id)}
                                    color={selected.includes(row._id) ? 'primary' : undefined}
                                    onChange={(event) => {
                                        setSelected((ids) =>
                                            event.target.checked
                                                ? ids.concat(row._id)
                                                : ids.filter((itemId) => itemId !== row._id),
                                        );
                                    }}
                                    slotProps={{checkbox: {sx: {textAlign: 'left'}}}}
                                    sx={{verticalAlign: 'text-bottom'}}
                                />
                            </td>
                            <td>
                                <Typography level="body-xs">{row.title}</Typography>
                            </td>
                            <td>
                                <Box sx={{display: 'flex', gap: 2, alignItems: 'center'}}>

                                    <div>
                                        <Typography level="body-xs">{getFormatedDate(row.createdAt)}</Typography>
                                        <Typography level="body-xs">Mis à jour
                                            le {getFormatedDate(row.updatedAt)}</Typography>
                                    </div>
                                </Box>

                            </td>
                            <td>
                                <ChipByStatePost state={row.active} />
                            </td>
                            <td>
                                <Box sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
                                    <RowMenu post={row}/>
                                </Box>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </Sheet>
            {/*<Box*/}
            {/*    className="Pagination-laptopUp"*/}
            {/*    sx={{*/}
            {/*        pt: 2,*/}
            {/*        gap: 1,*/}
            {/*        [`& .${iconButtonClasses.root}`]: {borderRadius: '50%'},*/}
            {/*        display: {*/}
            {/*            xs: 'none',*/}
            {/*            md: 'flex',*/}
            {/*        },*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <Button*/}
            {/*        size="sm"*/}
            {/*        variant="outlined"*/}
            {/*        color="neutral"*/}
            {/*        startDecorator={<KeyboardArrowLeftIcon/>}*/}
            {/*    >*/}
            {/*        Précédent*/}
            {/*    </Button>*/}

            {/*    <Box sx={{flex: 1}}/>*/}
            {/*    {['1', '2', '3', '…', '8', '9', '10'].map((page) => (*/}
            {/*        <IconButton*/}
            {/*            key={page}*/}
            {/*            size="sm"*/}
            {/*            variant={Number(page) ? 'outlined' : 'plain'}*/}
            {/*            color="neutral"*/}
            {/*        >*/}
            {/*            {page}*/}
            {/*        </IconButton>*/}
            {/*    ))}*/}
            {/*    <Box sx={{flex: 1}}/>*/}

            {/*    <Button*/}
            {/*        size="sm"*/}
            {/*        variant="outlined"*/}
            {/*        color="neutral"*/}
            {/*        endDecorator={<KeyboardArrowRightIcon/>}*/}
            {/*    >*/}
            {/*        Suivant*/}
            {/*    </Button>*/}
            {/*</Box>*/}
        </React.Fragment>
    );
}

export default PostTable;