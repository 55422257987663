import {Icon, LatLngExpression} from "leaflet";
import markerGreen from "../../Assets/Image/markerGreen.svg";
import markerRed from "../../Assets/Image/markerRed.svg";
import React, {useEffect, useState} from "react";
import {MapContainer, Marker, TileLayer, useMap} from "react-leaflet";
import {FireInterface} from "../../interface/FireInterface";

// @ts-ignore
const Recenter = ({lat, lng, zoom}) => {
  const map = useMap();
  useEffect(() => {
    map.setView([lat, lng], zoom);
  }, [lat, lng]);
  return null;
}

const iconGreen = new Icon({
  iconUrl: markerGreen,
  iconSize: [32, 32]
})

const iconRed = new Icon({
  iconUrl: markerRed,
  iconSize: [32, 32]
})

interface ListProps {
  itemsFire: FireInterface[]
}
const Map: React.FC<ListProps> = ({itemsFire = []}) => {
  const [position, setPosition] = useState({lat: 46.227638, lng: 2.213749}); // set default position

  return (
      <MapContainer
          style={{
            height: "100%",
            width: "100%",
          }}
          center={position}
          attributionControl={true}
          zoom={7}
          minZoom={3}
          scrollWheelZoom={true}
      >
        <TileLayer
            // className={'ion-hide'}
            attribution='<a target="_blank" href="mailto:firealertforest@gmail.com">Contact</a>'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {itemsFire.map((fire, key) => {
          let fireLocalization: LatLngExpression | any = fire.localization
          if (fire.state === "En cours") {
            return (

                <Marker
                    key={key}
                    position={fireLocalization}
                    icon={iconRed}
                />
            )
          }
          if (fire.state === "Terminé") {
            return (

                <Marker
                    key={key}
                    position={fireLocalization}
                    icon={iconGreen}
                />
            )
          }
        })}
        <Recenter lat={position.lat} lng={position.lng} zoom={7}/>
      </MapContainer>
  )
}

export default Map;