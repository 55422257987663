import * as React from 'react';
import {CssVarsProvider} from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import AddRoundedIcon from '@mui/icons-material/Add';

import {useEffect, useState} from "react";
import {getAPI, postAPI} from "../../Utils/api";


import Button from "@mui/joy/Button";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalClose from "@mui/joy/ModalClose";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";

import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import {useNavigate} from "react-router";
import {InterfacePost} from "../../interface/InterfacePost";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import PostTable from "../../components/PostTable/PostTable";
import {useAuth} from "../../contexts/AuthContext";


export default function PostList() {
    const {token} = useAuth();
    const [listPost, setListPost] = useState<InterfacePost[]>([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getAPI('posts', '');
                if (response.data.data && response.data.success) {
                    setListPost(response.data.data);
                }

            } catch (error) {

            }
        };

        fetchData();


    }, []);


    // Modal
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [title, setTitle] = useState<string>('');
    const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpenModal(false);
    };
    const navigate = useNavigate();

    const submitTitlePost = () => {
        const objPost = {
            title: title,
            active: false,
            description: "Please write content",
            image: "https://scontent-cdg4-2.xx.fbcdn.net/v/t39.30808-6/455040662_539051681779164_3965890826566145033_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=833d8c&_nc_ohc=mmUcrLKtDssQ7kNvgHgw-Am&_nc_ht=scontent-cdg4-2.xx&oh=00_AYBW2gqpt6011bSX2Ync8XMma887qdyoU62YaTKIpN1RzQ&oe=66D8D00F"
        }
        postAPI('post/', objPost, token).then((r) => {
            if (r.status === 201) {
                setOpenModal(false);
                navigate('/admin/article/show/' + r.data.data._id);
            }
        }).catch((e) => {

        })

    }


    return (
        <CssVarsProvider>
            <CssBaseline/>
            <Modal open={openModal} onClose={handleClose}>
                <ModalDialog
                    layout="center"
                    size="sm"
                    variant="plain"
                >
                    <ModalClose onClick={() => setOpenModal(false)}/>


                    <Typography
                        component="h2"
                        id="modal-title"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >
                        Nouveau article
                    </Typography>
                    <FormControl required>
                        <FormLabel>Titre</FormLabel>
                        <Input
                            type="text"
                            name="title"
                            onChange={(e) => setTitle(e.target.value)}
                            defaultValue={title}
                        />
                    </FormControl>
                    <Button type="submit" onClick={submitTitlePost}>Crée un nouveau article</Button>


                </ModalDialog>
            </Modal>
            <Box sx={{display: 'flex', minHeight: '100dvh'}}>
                <Header/>
                <Sidebar/>
                <Box
                    component="main"
                    className="MainContent"
                    sx={{
                        px: {xs: 2, md: 6},
                        pt: {
                            xs: 'calc(12px + var(--Header-height))',
                            sm: 'calc(12px + var(--Header-height))',
                            md: 3,
                        },
                        pb: {xs: 2, sm: 2, md: 3},
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 0,
                        height: '100dvh',
                        gap: 1,
                    }}
                >
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Breadcrumbs
                            size="sm"
                            aria-label="breadcrumbs"
                            separator={<ChevronRightRoundedIcon fontSize="small"/>}
                            sx={{pl: 0}}
                        >
                            <Link
                                underline="none"
                                color="neutral"
                                href="#some-link"
                                aria-label="Home"
                            >
                                <HomeRoundedIcon/>
                            </Link>
                            <Typography color="primary" fontWeight={500} fontSize={12}>
                                Articles
                            </Typography>
                        </Breadcrumbs>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            mb: 1,
                            gap: 1,
                            flexDirection: {xs: 'column', sm: 'row'},
                            alignItems: {xs: 'start', sm: 'center'},
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography level="h2" component="h1">
                            Articles
                        </Typography>
                        <Button
                            color="primary"
                            startDecorator={<AddRoundedIcon/>}
                            size="sm"
                            onClick={() => setOpenModal(true)}
                        >
                            Ajouter un nouvel article
                        </Button>
                    </Box>
                    <PostTable items={listPost}/>
                </Box>
            </Box>
        </CssVarsProvider>
    )
}