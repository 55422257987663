import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import ChipByStateFire from "../ChipByStateFire/ChipByStateFire";
import CardCover from "@mui/joy/CardCover";
import {Grid} from "@mui/joy";
import * as React from "react";
import {FireInterface} from "../../interface/FireInterface";
import moment from "moment";
import {useNavigate} from "react-router";


interface Props {
    item: FireInterface,
    key: number
}

const CardFireMobile: React.FC<Props> = ({item, key}) => {
    const navigate = useNavigate();
    const customFormat = "DD/MM/YYYY [à] HH:mm";
    const formattedDate = moment(new Date(item.createdAt)).format(customFormat);
    return (
        <Grid
            xs={12}
            md={6}
            lg={6}
            sx={{display: {xs: 'block', sm: 'block', md: 'none', lg: 'none'}}}
            key={item._id}
        >
            <Card
                variant="solid"
                invertedColors
                size="sm"
                sx={{
                    border: '1px solid',
                    borderColor: 'var(--joy-palette-neutral-outlinedBorder)',
                }}
                onClick={() => navigate("/feux/" + item._id)}

            >
                <CardContent
                    sx={{
                        mb: 'auto',
                        flexGrow: 0,
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <Box sx={{flex: 1}}>
                        <Typography level="title-md">{item.localization.cityName} - {item.localization.cityZipCode}</Typography>
                        <Typography level="body-xs" mt={0.5}>
                            Déclarée le {formattedDate}
                        </Typography>
                    </Box>
                    <ChipByStateFire state={item.state}/>
                </CardContent>
                <CardCover>
                    <img
                        alt=""
                        src="https://media.istockphoto.com/id/1266552048/fr/photo/feu-de-for%C3%AAt-le-temps-de-nuit-sur-la-montagne-avec-la-grande-fum%C3%A9e.jpg?s=612x612&w=0&k=20&c=wFzfKisuFxdrlVDq0Nm5o28v7EHxNsPj_w9e_MuQUtA="
                        srcSet="https://media.istockphoto.com/id/1266552048/fr/photo/feu-de-for%C3%AAt-le-temps-de-nuit-sur-la-montagne-avec-la-grande-fum%C3%A9e.jpg?s=612x612&w=0&k=20&c=wFzfKisuFxdrlVDq0Nm5o28v7EHxNsPj_w9e_MuQUtA="
                    />
                </CardCover>
                <CardCover
                    sx={{
                        background:
                            'linear-gradient(to bottom, rgba(0,0,0,0.8), rgba(0,0,0,0.12))',
                    }}
                />
            </Card>
        </Grid>
    )
}

export default CardFireMobile;