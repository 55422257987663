import * as React from 'react';
import {CssVarsProvider} from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Layout from '../../Component/Layout/Layout';
import HeaderNav from '../../Component/Header/HeaderNav';
import {Breadcrumbs, Grid} from "@mui/joy";
import CardFireMobile from "../../Component/CardFireMobile/CardFireMobile";
import CardFireDesktop from "../../Component/CardFireDesktop/CardFireDesktop";
import FooterNav from "../../Component/FooterNav/FooterNav";
import Map from "../../Component/Map/Map";
import {useEffect, useState} from "react";
import {FireInterface} from "../../interface/FireInterface";
import {getAPI} from "../../Utils/api";
import {LatLngExpression} from "leaflet";
import {Link} from "react-router-dom";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";

const Maps = () => {
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [listFilterFires, setListFilterFire] = useState<FireInterface[]>([]);
    const [listFires, setListFire] = useState<FireInterface[]>([]);
    const [filterEnded, setFilterEnded] = useState<boolean>(true);
    const [filterInProgress, setFilterInProgress] = useState<boolean>(true);

    // Loader
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);


    const [initialized, setInitialized] = useState(false);

    const [position, setPosition] = useState({lat: 46.227638, lng: 2.213749}); // set default position
    const onClickItemList = (e: React.MouseEvent<HTMLDivElement> | null, fire: FireInterface) => {
        if(fire){
            // Utilisez l'élément de liste associé (fire) ici
            let focusPostion: LatLngExpression | any = fire.localization;

            setPosition(focusPostion)
        }

    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getAPI('fires', '');

                if (response.data.data) {
                    // Trier les feux par date de création (createdAt) en ordre décroissant
                    const sortedFires = response.data.data.sort((a: FireInterface, b: FireInterface) =>
                        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
                    );
                    setListFire(sortedFires);
                }
            } catch (error) {
                console.error("Error fetching data", error);
            }
        };

        fetchData();
    }, []);

    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline/>
            <Layout.Root
                sx={{
                    gridTemplateColumns: {
                        xs: '1fr',
                        md: 'minmax(20%, 1fr) minmax(80%, 1fr)',
                    },
                    ...(drawerOpen && {
                        height: '100vh',
                        overflow: 'hidden',
                    }),
                }}
            >
                <Layout.Header>
                    <HeaderNav/>
                </Layout.Header>

                <Layout.Main sx={{
                   display: {xs: 'none', md: "block"}
                }}>

                    <Grid container spacing={2}>
                        <Breadcrumbs
                            size="sm"
                            aria-label="breadcrumbs"
                            separator={<ChevronRightRoundedIcon fontSize="small"/>}
                            sx={{pl: 0}}
                        >
                            <Link
                                to={"/"}
                                color="neutral"
                                aria-label="Home"
                            >
                                <HomeRoundedIcon/>
                            </Link>
                            <Link
                                to={"/news"}
                                color="neutral"
                                aria-label="Home"
                                style={{textDecoration: "none"}}
                            >
                                <Typography color="primary" fontWeight={500} fontSize={12}>
                                    Carte
                                </Typography>
                            </Link>
                        </Breadcrumbs>
                        {listFires.map((fire, key) => {
                            return (
                                <>
                                    <CardFireMobile item={fire} key={key}/>
                                    <CardFireDesktop fullWidth={true} redirectToMaps={false} item={fire} key={key}/>
                                </>
                            )
                        })}
                    </Grid>
                </Layout.Main>
                <Layout.Main>
                    <Grid container spacing={2}
                          sx={{
                              '--Grid-borderWidth': '1px',
                              borderTop: 'var(--Grid-borderWidth) solid',
                              borderLeft: 'var(--Grid-borderWidth) solid',
                              borderColor: 'divider',
                              borderRadius: 20,
                              overflow: "hidden",
                              height: "100%"
                          }}>
                        <Map itemsFire={listFires}/>
                    </Grid>
                </Layout.Main>
                <Layout.Footer>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
                            gap: 2,
                        }}
                    >
                        <FooterNav/>
                    </Box>
                </Layout.Footer>
            </Layout.Root>
        </CssVarsProvider>
    );
}

export default Maps;