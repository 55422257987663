import * as React from 'react';
import {useEffect, useState} from 'react';
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Link from "@mui/joy/Link";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import Typography from "@mui/joy/Typography";
import {CssVarsProvider} from "@mui/joy/styles";
import {useNavigate, useParams} from 'react-router';
import {getAPI, postAPI, putAPI} from "../../Utils/api";
import Sheet from "@mui/joy/Sheet";
import Divider from "@mui/joy/Divider";
import Chip from "@mui/joy/Chip";
import {AspectRatio, IconButton, SelectOption, Skeleton, Snackbar, Stack, styled, SvgIcon} from '@mui/joy';

import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalClose from "@mui/joy/ModalClose";
import Select from "@mui/joy/Select";
import Option from '@mui/joy/Option';
import Button from "@mui/joy/Button";

import {ContentState, convertToRaw, EditorState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import axios from 'axios';
import {InterfacePost} from "../../interface/InterfacePost";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import {getFormatedDate} from "../../Utils/date";
import {useAuth} from "../../contexts/AuthContext";
import {ChipByStatePost} from "../Chip/ChipStatePost/ChipStatePost";
import {AddAlert, Subscriptions} from "@mui/icons-material";

const PostShow: React.FC = () => {
    const {token} = useAuth();
    const {postId} = useParams();
    const navigate = useNavigate();
    const [post, setPost] = useState<InterfacePost>();

    // State
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openToast, setOpenToast] = useState<boolean>(false);
    const [themeOpenToast, setThemeOpenToast] = useState<"primary" | "neutral" | "danger" | "success" | "warning" | undefined>();
    const [openMessageToast, setOpenMessageToast] = useState<string>("");

    const [modalType, setModalType] = useState<string>("state");
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );
    const [content, setContent] = useState<string>('');
    const [title, setTitle] = useState<string>('');


    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            // Créez un FormData et ajoutez-y le fichier sélectionné
            const formData = new FormData();
            formData.append('image', e.target.files[0]);

            try {
                const response = await postAPI('post/' + postId + '/image', formData, token);
                console.log('Image uploaded successfully:', response.data);
                window.location.reload();
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        }
    };

    useEffect(() => {
        getAPI('post/' + postId).then((r) => {
            if (r.status === 200) {

                setPost(r.data.data);

                const contentBlock = htmlToDraft(r.data.data.description);
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                setTitle(r.data.data.title);
                setEditorState(editorState);
            } else {
                navigate('/admin/article/list');
            }
        })
    }, []);

    const handleClickOpenState = () => {
        setModalType("state");
        setOpenModal(true);
    };
    const handleClickOpenTitle = () => {
        setModalType("title");
        setOpenModal(true);
    };

    const handleClickOpenNotify = () => {
        setModalType("notify");
        setOpenModal(true);
    };


    const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpenModal(false);
    };

    const submitStatePost = () => {
        let stateModified = document.getElementById('statePost');
        console.log(stateModified?.textContent);
        const oldPost = post;

        if (stateModified && oldPost) {

            if (stateModified.textContent === "publié") {
                oldPost.active = true;
            } else {
                oldPost.active = false;
            }
            console.log(oldPost);

            setPost(oldPost);
            putAPI('post/' + postId, oldPost, token).then((r) => {
                if (r.status === 401 && !r.data.success) {
                    setOpenMessageToast(r.data.message);
                    setThemeOpenToast("danger")
                    setOpenToast(true);
                    console.log(r.data.message);
                } else {
                    setOpenMessageToast("Etat mis à jour !")
                    setThemeOpenToast("success")
                    setOpenToast(true);
                    setOpenModal(false);
                }

            }).catch((e) => {

                console.log(e);
            })

        }
    }

    const submitTitlePost = () => {
        const oldPost = post;

        if (oldPost) {
            oldPost.title = title;
            setPost(oldPost);
            putAPI('post/' + postId, oldPost, token ?? null).then((r) => {
                if (r.status === 401 && !r.data.success) {
                    setOpenMessageToast(r.data.message);
                    setThemeOpenToast("danger")
                    setOpenToast(true);
                    console.log(r.data.message);
                } else {
                    setOpenMessageToast("Titre mis à jour !")
                    setThemeOpenToast("success")
                    setOpenToast(true);
                    setOpenModal(false);
                }


            }).catch((e) => {
                console.log(e);
            })
        }
    }

    const handleClickConfirmNotify = () => {

        postAPI('notify', {type: "post", dataId: post?._id}, token).then((r) => {
            if (r.status === 404 && !r.data.success) {
                setOpenMessageToast(r.data.message);
                setThemeOpenToast("danger")
                setOpenToast(true);
                console.log(r.data.message);
            } else {
                setOpenMessageToast("Lancement de la campagne en cours.")
                setThemeOpenToast("success")
                setOpenToast(true);
                setOpenModal(false);
            }

        }).catch((e) => {
            console.log(e);
        })

    }


    interface CustomRenderValueProps {
        selected: SelectOption<string> | null;  // Ajoutez un type approprié pour "selected"
    }

    const CustomRenderValue: React.FC<CustomRenderValueProps> = ({selected}) => {
        console.log(selected);
        return (
            <Box sx={{display: 'flex', gap: '0.25rem'}}>
                {selected ? selected.value : ""}
            </Box>
        )
    }

    const submitPost = () => {
        if (post) {
            post.description = content;

            putAPI('post/' + postId, post, token ?? null).then((r) => {
                if (r.status === 401 && !r.data.success) {
                    setOpenMessageToast(r.data.message);
                    setThemeOpenToast("danger")
                    setOpenToast(true);
                    console.log(r.data.message);
                } else {
                    setOpenMessageToast("Description mis à jour !")
                    setThemeOpenToast("success")
                    setOpenToast(true);
                    setOpenModal(false);
                }
            }).catch((e) => {
                console.log(e);
                setOpenMessageToast("Erreur interne");
                setThemeOpenToast("danger")
                setOpenToast(true);
            })
        }


    }

    const VisuallyHiddenInput = styled('input')`
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        bottom: 0;
        left: 0;
        white-space: nowrap;
        width: 1px;
    `;

    if (post) {
        return (
            <CssVarsProvider>
                <CssBaseline/>
                <Snackbar
                    autoHideDuration={3000}
                    open={openToast}
                    variant={"solid"}
                    color={themeOpenToast}
                    onClose={(event, reason) => {
                        setOpenToast(false);
                    }}
                >
                    {openMessageToast}
                </Snackbar>
                <Modal open={openModal} onClose={handleClose}>
                    <ModalDialog
                        layout="center"
                        size="lg"  // Vous pouvez utiliser 'lg' pour large ou définir une taille personnalisée
                        variant="plain"
                        sx={{
                            maxWidth: '700px', // Ajustez cette valeur en fonction de vos besoins
                            width: '100%',
                        }}
                    >
                        <ModalClose onClick={() => setOpenModal(false)}/>
                        {modalType === "state" ?
                            <>
                                <Typography
                                    component="h2"
                                    id="modal-title"
                                    level="h4"
                                    textColor="inherit"
                                    fontWeight="lg"
                                    mb={1}
                                >
                                    Mettre à jour l'état
                                </Typography>
                                <Typography id="modal-desc" textColor="text.tertiary">
                                    <Select
                                        id={"statePost"}
                                        defaultValue={post.active ? "publié" : "brouillon"}
                                        renderValue={(selected) => (
                                            <CustomRenderValue selected={selected}/>
                                        )}
                                        sx={{
                                            minWidth: '15rem',
                                        }}
                                        slotProps={{
                                            listbox: {
                                                sx: {
                                                    width: '100%',
                                                },
                                            },
                                        }}
                                    >
                                        <Option value={"publié"}>Publié</Option>
                                        <Option value={"brouillon"}>Brouillon</Option>
                                    </Select>
                                </Typography>
                                <Button type="submit" onClick={submitStatePost}>Mettre à jour l'état</Button>
                            </> : ""
                        }
                        {modalType === "title" ?
                            <>
                                <Typography
                                    component="h2"
                                    id="modal-title"
                                    level="h4"
                                    textColor="inherit"
                                    fontWeight="lg"
                                    mb={1}
                                >
                                    Mettre à jour le titre
                                </Typography>
                                <FormControl required sx={{width: '100%'}}> {/* Pour occuper toute la largeur */}
                                    <FormLabel>Titre</FormLabel>
                                    <Input
                                        type="text"
                                        name="title"
                                        onChange={(e) => setTitle(e.target.value)}
                                        defaultValue={title}
                                        sx={{
                                            width: '100%', // Ajuste la largeur de l'input
                                            minWidth: '500px', // Vous pouvez ajuster cette valeur
                                        }}
                                    />
                                </FormControl>
                                <Button type="submit" onClick={submitTitlePost}>Mettre à jour le titre</Button>
                            </> : ""
                        }
                        {modalType === "notify" ?
                            <>
                                <Typography
                                    component="h2"
                                    id="modal-title"
                                    level="h4"
                                    textColor="inherit"
                                    fontWeight="lg"
                                    mb={1}
                                >
                                    Notification
                                </Typography>
                                Voulez-vous informer aux utilisateurs de la présence de l'article?
                                <Button type="submit" onClick={handleClickConfirmNotify}>Lancer la campagne</Button>
                            </> : ""
                        }
                    </ModalDialog>
                </Modal>
                <Box sx={{display: 'flex'}}>
                    <Header/>
                    <Sidebar/>
                    <Box
                        component="main"
                        className="MainContent"
                        sx={{
                            px: {xs: 2, md: 6},
                            pt: {
                                xs: 'calc(12px + var(--Header-height))',
                                sm: 'calc(12px + var(--Header-height))',
                                md: 3,
                            },
                            pb: {xs: 2, sm: 2, md: 3},
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            minWidth: 0,
                            height: '100dvh',
                            gap: 1,
                            mb: 10,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                mb: 1,
                                gap: 1,
                                flexDirection: {xs: 'column', sm: 'row'},
                                alignItems: {xs: 'start', sm: 'center'},
                                flexWrap: 'wrap',
                                justifyContent: 'space-between',
                            }}

                        >
                            <Typography level="h2" component="h1" onClick={handleClickOpenTitle}>
                                {post?.title}
                            </Typography>
                            {post.active === true ?
                                <IconButton color={"primary"} variant={"soft"} onClick={handleClickOpenNotify}>
                                    <AddAlert/>
                                </IconButton> : ""
                            }

                        </Box>
                        <React.Fragment>
                            <Sheet
                                component="li"
                                variant="outlined"
                                sx={{
                                    borderRadius: 'sm',
                                    p: 1,
                                    listStyle: 'none',
                                }}
                            >

                                <Editor
                                    editorState={editorState}
                                    onEditorStateChange={newState => {
                                        setEditorState(newState);
                                        setContent(draftToHtml(convertToRaw(newState.getCurrentContent())));
                                    }}
                                />
                                <Divider/>
                                <Box sx={{py: 2, display: 'flex'}}>
                                    <AspectRatio variant="plain" sx={{width: 300}}>
                                        <Skeleton loading={false}>
                                            <img
                                                src={
                                                    post.image
                                                }
                                                alt=""
                                            />
                                        </Skeleton>
                                    </AspectRatio>
                                    <Button
                                        component="label"
                                        role={undefined}
                                        tabIndex={-1}
                                        variant="outlined"
                                        color="neutral"
                                        startDecorator={
                                            <SvgIcon>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                                                    />
                                                </svg>
                                            </SvgIcon>
                                        }
                                    >
                                        Upload a file
                                        <VisuallyHiddenInput type="file" onChange={handleFileChange}/>
                                    </Button>
                                </Box>
                                <Divider/>
                                <Box sx={{mt: 1.5, display: 'flex', gap: 1}}>
                                    <Chip
                                        variant="outlined"
                                        color="neutral"
                                        size="sm"
                                    >
                                        Crée le : {getFormatedDate(post.createdAt)}
                                    </Chip>
                                    <Chip
                                        variant="outlined"
                                        color="neutral"
                                        size="sm"
                                    >
                                        Update : {getFormatedDate(post.updatedAt)}
                                    </Chip>
                                    <ChipByStatePost state={post.active} onClick={handleClickOpenState}/>
                                    <Button type="submit" sx={{marginLeft: "auto"}}
                                            onClick={submitPost}>Enregistrer</Button>
                                </Box>
                            </Sheet>
                        </React.Fragment>
                    </Box>
                </Box>
            </CssVarsProvider>
        )
    } else {
        return (<div>toto</div>)
    }

}

export default PostShow;