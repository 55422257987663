import * as React from "react";
import {CssVarsProvider} from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import Layout from "../../Component/Layout/Layout";
import HeaderNav from "../../Component/Header/HeaderNav";
import Typography from "@mui/joy/Typography";
import Box from "@mui/joy/Box";
import FooterNav from "../../Component/FooterNav/FooterNav";
import {Breadcrumbs, Skeleton, Stack} from "@mui/joy";
import {useNavigate, useParams} from "react-router";
import {useEffect, useState} from "react";
import {InterfacePost} from "../../interface/InterfacePost";
import {getAPI} from "../../Utils/api";
import moment from "moment/moment";
import AspectRatio from "@mui/joy/AspectRatio";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import {Link} from "react-router-dom";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
const ShowNews = () => {
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const {postId} = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [post, setPost] = useState<InterfacePost>({
        _id: "",
        title: "",
        slug: "",
        active: false,
        image: "",
        createdAt: new Date(),
        updatedAt: new Date(),
        description: ""
    });

    useEffect(() => {
        getAPI('post/' + postId).then((r) => {
            console.log(r)
            if (r.status === 200) {
                setPost(r.data.data);

            } else {
                navigate('/news');
            }
        })
    }, []);

    const customFormat = "DD/MM/YYYY [à] HH:mm";
    const formattedDate = moment(new Date(post.createdAt)).format(customFormat);
    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline/>
            <Layout.Root
                sx={{
                    gridTemplateColumns: {
                        xs: '1fr',
                        md: '1fr',
                    },
                    ...(drawerOpen && {
                        height: '100vh',
                        overflow: 'hidden',
                    }),
                }}
            >
                <Layout.Header>
                    <HeaderNav/>
                </Layout.Header>
                <Layout.Main>
                    <Stack
                        spacing={4}
                        sx={{
                            display: 'flex',
                            maxWidth: '800px',
                            mx: 'auto',
                            px: { xs: 2, md: 6 },
                            py: { xs: 2, md: 3 },
                        }}
                    >
                        <Box sx={{ m: 'auto' }}>

                                <Breadcrumbs
                                    size="sm"
                                    aria-label="breadcrumbs"
                                    separator={<ChevronRightRoundedIcon fontSize="small"/>}
                                    sx={{pl: 0}}
                                >
                                    <Link
                                        to={"/"}
                                        color="neutral"
                                        aria-label="Home"
                                    >
                                        <HomeRoundedIcon/>
                                    </Link>
                                    <Link
                                        to={"/news"}
                                        color="neutral"
                                        aria-label="Home"
                                        style={{textDecoration: "none"}}
                                    >
                                        <Typography color="neutral" fontWeight={500} fontSize={12}>
                                            Actualités
                                        </Typography>
                                    </Link>
                                    <Typography color={"primary"} fontSize={12} fontWeight={500}>
                                        {post?.title}
                                    </Typography>
                                </Breadcrumbs>

                            <AspectRatio variant="plain" sx={{borderRadius: 20, boxShadow: "sm"}}>
                                <Skeleton loading={loading}>
                                    <img
                                        src={
                                            loading
                                                ? 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
                                                : post.image
                                        }
                                        alt=""
                                    />
                                </Skeleton>
                            </AspectRatio>
                        </Box>

                        <Typography level="h1">
                            {post?.title}
                        </Typography>
                        <Typography level="title-sm" sx={{
                            marginTop: "0 !important"
                        }}>
                            {formattedDate}
                        </Typography>
                        <Typography level="body-lg">
                            <div dangerouslySetInnerHTML={{__html: post.description}} />
                        </Typography>
                    </Stack>
                </Layout.Main>
                <Layout.Footer>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
                            gap: 2,
                        }}
                    >
                        <FooterNav/>
                    </Box>
                </Layout.Footer>
            </Layout.Root>
        </CssVarsProvider>)
}

export default ShowNews