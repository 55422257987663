import CssBaseline from "@mui/joy/CssBaseline";
import Layout from "../../Component/Layout/Layout";
import HeaderNav from "../../Component/Header/HeaderNav";
import Box from "@mui/joy/Box";
import {CssVarsProvider} from "@mui/joy/styles";
import * as React from "react";
import FooterNav from "../../Component/FooterNav/FooterNav";
import Typography from "@mui/joy/Typography";
import {Grid} from "@mui/joy";
import CardFireMobile from "../../Component/CardFireMobile/CardFireMobile";
import CardFireDesktop from "../../Component/CardFireDesktop/CardFireDesktop";
import {useEffect, useState} from "react";
import {FireInterface} from "../../interface/FireInterface";
import {getAPI, postAPI} from "../../Utils/api";
import CardNewsMobile from "../../Component/CardNewsMobile/CardNewsMobile";
import CardNewsDesktop from "../../Component/CardNewsDesktop/CardNewsDesktop";
import {InterfacePost} from "../../interface/InterfacePost";
import axios from "axios";
import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';

// Configuration Firebase
const firebaseConfig = {
    apiKey: "AIzaSyBbmtUfv6gGD3P51Wl7lHYsp6fMEXNuhCA",
    authDomain: "fdf-83.firebaseapp.com",
    projectId: "fdf-83",
    storageBucket: "fdf-83.appspot.com",
    messagingSenderId: "562905051801",
    appId: "1:562905051801:web:7eee36e15ce564b5483e08",
    measurementId: "G-6R8B5W53WR"
};

// Initialiser Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

// const messaging = firebase.messaging();

const Home = () => {
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [listFires, setListFire] = useState<FireInterface[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getAPI('fires', '');

                if (response.data.data) {
                    const sortedFires = response.data.data.sort((a: FireInterface, b: FireInterface) =>
                        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
                    );
                    setListFire(sortedFires);
                }
            } catch (error) {
                console.error("Error fetching data", error);
            }
        };

        // const requestPermission = async () => {
        //     try {
        //         await Notification.requestPermission();
        //         if (Notification.permission === 'granted') {
        //             const token = await messaging.getToken({vapidKey: 'BJsAqMB8QEabSNc-Ys-OLnoxzhu64NW0WeFdDBY1aSeLGa5jhQVktYaTDJNe88VOuUx8J55QEHwuSFSveR8bj0o'});
        //             // console.log('FCM Token:', token);
        //             // Envoyer le token au backend pour le stocker
        //             await postAPI('notify/save-token-fcm', {token}, null);
        //         } else {
        //             console.log('Permission de notification refusée');
        //         }
        //     } catch (error) {
        //         console.error('Erreur lors de la demande de permission de notification:', error);
        //     }
        // };
        //
        // requestPermission();
        fetchData();

        // Gérer les messages en premier plan
        // messaging.onMessage((payload) => {
        //     console.log('Message reçu en premier plan:', payload);
        //     // Afficher la notification ou mettre à jour l'interface utilisateur
        //     const {title, body, icon} = payload.notification;
        //     new Notification(title, {body, icon});
        // });
    }, []);

    const [listNews, setListNews] = useState<InterfacePost[]>([]);
    useEffect(() => {
        const fetchData = async () => {
            try {

                const response = await getAPI('posts/active', '');

                if (response.data.data) {
                    const sortedNews = response.data.data.sort((a: InterfacePost, b: InterfacePost) =>
                        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
                    );
                    setListNews(sortedNews);
                }

            } catch (error) {
            }
        };

        fetchData();

    }, [1]);
    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline/>
            <Layout.Root
                sx={{
                    gridTemplateColumns: {
                        xs: '1fr',
                        md: 'minmax(50%, 1fr) minmax(50%, 420px)',
                    },
                    ...(drawerOpen && {
                        height: '100vh',
                        overflow: 'hidden',
                    }),
                }}
            >
                <Layout.Header>
                    <HeaderNav/>
                </Layout.Header>
                <Layout.Main>
                    <Typography level="h2" component="h1">
                        Derniers Feux
                    </Typography>
                    <Grid container spacing={3}>
                        {listFires.map((fire, key) => {
                            return (
                                <>
                                    <CardFireMobile item={fire} key={key}/>
                                    <CardFireDesktop redirectToMaps={true} fullWidth={false} item={fire} key={key}/>
                                </>
                            )
                        })}

                    </Grid>
                </Layout.Main>
                <Layout.Main>
                    <Typography level="h2" component="h1">Dernieres actualités</Typography>
                    <Grid container spacing={3}>
                        {listNews.map((item, key) => {
                            return (
                                <>
                                    <CardNewsMobile item={item} key={key}/>
                                    <CardNewsDesktop item={item} key={key}/>
                                </>
                            )
                        })}

                    </Grid>
                </Layout.Main>
                <Layout.Footer>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
                            gap: 2,
                        }}
                    >
                        <FooterNav/>
                    </Box>
                </Layout.Footer>
            </Layout.Root>
        </CssVarsProvider>
    )
}

export default Home;