import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes, Outlet, Navigate} from "react-router-dom";
import {ReactNotifications} from 'react-notifications-component';
import "./Assets/Style/index.scss";
import Maps from "./Page/Maps/Maps";
import Home from "./Page/Home/Home";
import News from "./Page/News/News";
import LegalNotice from "./Page/LegalNotice/LegalNotice";
import PrivacyPolicy from "./Page/PrivacyPolicy/PrivacyPolicy";
import ShowNews from "./Page/News/ShowNews";
import ShowFire from "./Page/Fire/ShowFire";
import type {} from '@mui/lab/themeAugmentation';
import '@mui/lab/themeAugmentation';
import LoginPage from './pages/Login/LoginPage';
import {AuthProvider, useAuth} from "./contexts/AuthContext";
import DashboardPage from "./pages/Dashboard/DashboardPage";
import PostList from "./pages/Post/PostList";
import PostShow from "./components/Post/PostShow";
import * as Sentry from "@sentry/react";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const PublicRoute = () => {
    const {isAuthenticated} = useAuth();
    return isAuthenticated ? <Navigate to="/admin/dashboard"/> : <Outlet/>
}

const PrivateRoute = () => {
    const {isAuthenticated} = useAuth();
    return isAuthenticated ? <Outlet/> : <Navigate to="/admin/login"/>;
}

Sentry.init({
    dsn: "https://005b4261fac6693630fa860a251f50e7@o4507878032211968.ingest.de.sentry.io/4507878034767952",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

root.render(
    <React.StrictMode>
        <AuthProvider>
            <BrowserRouter>
                <ReactNotifications/>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/news" element={<News/>}/>
                    <Route path="/news/:postId/:slug" element={<ShowNews/>}/>
                    <Route path="/feux/:fireId" element={<ShowFire/>}/>
                    <Route path="/maps" element={<Maps/>}/>
                    <Route path="/legal-notice" element={<LegalNotice/>}/>
                    <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                    <Route path={'/admin/login'} element={<PublicRoute/>}>
                        <Route path={"/admin/login"} element={<LoginPage/>}/>
                    </Route>
                    <Route path={'/admin/dashboard'} element={<PrivateRoute/>}>
                        <Route path={"/admin/dashboard"} element={<DashboardPage/>}/>
                    </Route>
                    <Route path="/admin/article/list" element={<PrivateRoute/>}>
                        <Route path="/admin/article/list" element={<PostList/>}/>
                    </Route>
                    <Route path="/admin/article/show/:postId" element={<PrivateRoute/>}>
                        <Route path="/admin/article/show/:postId" element={<PostShow/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        </AuthProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
