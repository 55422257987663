import * as React from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';
import LinearProgress from '@mui/joy/LinearProgress';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Stack from '@mui/joy/Stack';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import SupportRoundedIcon from '@mui/icons-material/SupportRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import BrightnessAutoRoundedIcon from '@mui/icons-material/BrightnessAutoRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {useLocation, useNavigate} from "react-router";
import logoFDF from "../../assets/Image/logo.jpg"
import {useAuth} from "../../contexts/AuthContext";

export default function Sidebar() {
    const { userInfo, logout } = useAuth();


    //assigning location variable
    const location = useLocation();
    //destructuring pathname from location
    const { pathname } = location;
    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate('/');  // Redirige l'utilisateur vers la page de connexion après la déconnexion
    };

    if (!userInfo) {
        return <div>Loading...</div>;
    }
    return (
        <Sheet
            className="Sidebar"
            sx={{
                position: { xs: 'fixed', md: 'sticky' },
                transform: {
                    xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
                    md: 'none',
                },
                transition: 'transform 0.4s, width 0.4s',
                zIndex: 10000,
                height: '100dvh',
                width: 'var(--Sidebar-width)',
                top: 0,
                p: 2,
                flexShrink: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRight: '1px solid',
                borderColor: 'divider',
            }}
        >
            <GlobalStyles
                styles={(theme) => ({
                    ':root': {
                        '--Sidebar-width': '220px',
                        [theme.breakpoints.up('lg')]: {
                            '--Sidebar-width': '240px',
                        },
                    },
                })}
            />
            <Box
                className="Sidebar-overlay"
                sx={{
                    position: 'fixed',
                    zIndex: 9998,
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    opacity: 'var(--SideNavigation-slideIn)',
                    backgroundColor: 'var(--joy-palette-background-backdrop)',
                    transition: 'opacity 0.4s',
                    transform: {
                        xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
                        lg: 'translateX(-100%)',
                    },
                }}
            />
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Typography level="title-lg">FDF 83 - Admin</Typography>
            </Box>

            <Box
                sx={{
                    minHeight: 0,
                    overflow: 'hidden auto',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    [`& .${listItemButtonClasses.root}`]: {
                        gap: 1.5,
                    },
                }}
            >
                <List
                    size="sm"
                    sx={{
                        gap: 1,
                        '--List-nestedInsetStart': '30px',
                        '--ListItem-radius': (theme) => theme.vars.radius.sm,
                    }}
                >

                    <ListItem>
                        <ListItemButton className={splitLocation[2] === "dashboard" ? "Mui-selected" : ""} onClick={() => {navigate("/admin/dashboard")}}>
                            <HomeRoundedIcon />
                            <ListItemContent>
                                <Typography level="title-sm">Home</Typography>
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton className={splitLocation[2] === "article" ? "Mui-selected" : ""} onClick={() => {navigate("/admin/article/list")}}>
                            <DashboardRoundedIcon />
                            <ListItemContent>
                                <Typography level="title-sm">Articles</Typography>
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>

                    <ListItem>
                        <ListItemButton className={splitLocation[1] === "fire" ? "Mui-selected" : ""} onClick={() => {navigate("/fire/list")}}>
                            <DashboardRoundedIcon />
                            <ListItemContent>
                                <Typography level="title-sm">Feux</Typography>
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>



                </List>

                {/*<List*/}
                {/*    size="sm"*/}
                {/*    sx={{*/}
                {/*        mt: 'auto',*/}
                {/*        flexGrow: 0,*/}
                {/*        '--ListItem-radius': (theme) => theme.vars.radius.sm,*/}
                {/*        '--List-gap': '8px',*/}
                {/*        mb: 2,*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <ListItem>*/}
                {/*        <ListItemButton>*/}
                {/*            <SupportRoundedIcon />*/}
                {/*            Support*/}
                {/*        </ListItemButton>*/}
                {/*    </ListItem>*/}
                {/*    <ListItem>*/}
                {/*        <ListItemButton>*/}
                {/*            <SettingsRoundedIcon />*/}
                {/*            Settings*/}
                {/*        </ListItemButton>*/}
                {/*    </ListItem>*/}
                {/*</List>*/}
                {/*<Card*/}
                {/*    invertedColors*/}
                {/*    variant="soft"*/}
                {/*    color="danger"*/}
                {/*    size="sm"*/}
                {/*    sx={{ boxShadow: 'none' }}*/}
                {/*>*/}
                {/*    <Stack direction="row" justifyContent="space-between" alignItems="center">*/}
                {/*        <Typography level="title-sm">Des feux en attente</Typography>*/}
                {/*    </Stack>*/}
                {/*    <Typography level="body-xs">*/}
                {/*    Attention, vous avez des feux en attente de validation*/}
                {/*    </Typography>*/}

                {/*    <Button size="sm" variant="solid">*/}
                {/*        Voir les feux*/}
                {/*    </Button>*/}
                {/*</Card>*/}
            </Box>
            {/*<Divider />*/}
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Avatar
                    variant="outlined"
                    size="sm"
                    srcSet={logoFDF}
                />
                <Box sx={{ minWidth: 0, flex: 1 }}>
                    <Typography level="title-sm">{userInfo.username}.</Typography>
                    <Typography level="body-xs">{userInfo.email}</Typography>
                </Box>
                <IconButton size="sm" variant="plain" color="danger" onClick={handleLogout}>
                    <LogoutRoundedIcon />
                </IconButton>
            </Box>
        </Sheet>
    );
}