import moment from "moment";

/**
 * @return string
 * @param date
 */
export const getFormatedDate = (date: Date): string => {

    const customFormat = "DD/MM/YYYY [à] HH:mm";
    return moment(new Date(date)).format(customFormat);
}