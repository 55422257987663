import * as React from "react";
import {CssVarsProvider} from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import Layout from "../../Component/Layout/Layout";
import HeaderNav from "../../Component/Header/HeaderNav";
import Typography from "@mui/joy/Typography";
import Box from "@mui/joy/Box";
import FooterNav from "../../Component/FooterNav/FooterNav";
import CardNewsMobile from "../../Component/CardNewsMobile/CardNewsMobile";
import CardNewsDesktop from "../../Component/CardNewsDesktop/CardNewsDesktop";
import {Breadcrumbs, Grid} from "@mui/joy";
import {useEffect, useState} from "react";
import {InterfacePost} from "../../interface/InterfacePost";
import {getAPI} from "../../Utils/api";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import {Link} from "react-router-dom";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";

const News = () => {
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [listNews, setListNews] = useState<InterfacePost[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {

                const response = await getAPI('posts/active', '');

                if (response.data.data) {
                    const sortedNews = response.data.data.sort((a: InterfacePost, b: InterfacePost) =>
                        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
                    );
                    setListNews(sortedNews);
                }

            } catch (error) {
            }
        };

        fetchData();

    }, [1]);
    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline/>
            <Layout.Root
                sx={{
                    gridTemplateColumns: {
                        xs: '1fr',
                        md: 'minmax(100%, 1fr)',
                    },
                    ...(drawerOpen && {
                        height: '100vh',
                        overflow: 'hidden',
                    }),
                }}
            >
                <Layout.Header>
                    <HeaderNav/>
                </Layout.Header>
                <Layout.Main>
                    <Breadcrumbs
                        size="sm"
                        aria-label="breadcrumbs"
                        separator={<ChevronRightRoundedIcon fontSize="small"/>}
                        sx={{pl: 0}}
                    >
                        <Link
                            to={"/"}
                            color="neutral"
                            aria-label="Home"
                        >
                            <HomeRoundedIcon/>
                        </Link>

                        <Typography color="primary" fontWeight={500} fontSize={12}>
                            Actualités
                        </Typography>
                    </Breadcrumbs>
                    <Typography level="h2" component="h1">
                        Dernieres actualités
                    </Typography>
                    <Grid container spacing={3}>
                        {listNews.map((item, key) => {
                            return (
                                <>
                                    <CardNewsMobile item={item} key={key}/>
                                    <CardNewsDesktop item={item} key={key}/>
                                </>
                            )
                        })}

                    </Grid>
                </Layout.Main>
                <Layout.Footer>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
                            gap: 2,
                        }}
                    >
                        <FooterNav/>
                    </Box>
                </Layout.Footer>
            </Layout.Root>
        </CssVarsProvider>
    )
}

export default News;