import Chip from "@mui/joy/Chip";
import {ColorPaletteProp} from "@mui/joy";
import React from "react";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";
import {getStateFireStringToNumber} from "../../Utils/state";

interface ChipByStateProps {
    state: string,
    onClick?: () => void
}


const ChipByStateFire: React.FC<ChipByStateProps> = ({state, onClick}) => {
    return (
        <Chip
            variant="solid"
            size="md"
            startDecorator={
                {
                    "En cours": <PriorityHighRoundedIcon/>,
                    "Maitrisé": <PriorityHighRoundedIcon/>,
                    "Terminé": <CheckRoundedIcon/>,
                }[state]
            }
            color={
                {
                    "En cours": 'warning',
                    "Maitrisé": 'danger',
                    "Terminé": 'success',
                }[state] as ColorPaletteProp
            }
            onClick={onClick}
        >
            {state}

        </Chip>
    )
}

export default ChipByStateFire;