import * as React from "react";
import {CssVarsProvider} from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import Layout from "../../Component/Layout/Layout";
import HeaderNav from "../../Component/Header/HeaderNav";
import Typography from "@mui/joy/Typography";
import Box from "@mui/joy/Box";
import FooterNav from "../../Component/FooterNav/FooterNav";
import {Breadcrumbs, Skeleton, Stack} from "@mui/joy";
import {useNavigate, useParams} from "react-router";
import {useEffect, useState} from "react";
import {InterfacePost} from "../../interface/InterfacePost";
import {getAPI} from "../../Utils/api";
import moment from "moment/moment";
import AspectRatio from "@mui/joy/AspectRatio";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import {Link} from "react-router-dom";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import {FireInterface} from "../../interface/FireInterface";
import Timeline from "../../components/Timeline/Timeline";
import ChipByStateFire from "../../Component/ChipByStateFire/ChipByStateFire";

const ShowFire = () => {

    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const {fireId} = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [fire, setFire] = useState<FireInterface>({
        localization: {
            lat: "",
            lng: "",
            DFCI: "",
            address: "",
            cityZipCode: "",
            cityName: "",
        },
        _id: "",
        image: "",
        state: "",
        createdAt: new Date(),
        updatedAt: new Date(),
        superficial: "",
        pompiers: 0,
        hbe: 0,
        milan: 0,
        historizes: []
    });

    useEffect(() => {
        getAPI('fire/' + fireId).then((r) => {
            console.log(r)
            if (r.status === 200) {
                setFire(r.data.data);

            } else {
                navigate('/maps');
            }
        })
    }, []);
    const customFormat = "DD/MM/YYYY [à] HH:mm";
    const formattedDate = moment(new Date(fire.createdAt)).format(customFormat);
    const formattedDateUpdated = moment(new Date(fire.updatedAt)).format(customFormat);
    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline/>
            <Layout.Root
                sx={{
                    gridTemplateColumns: {
                        xs: '1fr',
                        md: '1fr',
                    },
                    ...(drawerOpen && {
                        height: '100vh',
                        overflow: 'hidden',
                    }),
                }}
            >
                <Layout.Header>
                    <HeaderNav/>
                </Layout.Header>
                <Layout.Main>
                    <Stack
                        spacing={4}
                        sx={{
                            display: 'flex',
                            maxWidth: '800px',
                            mx: 'auto',
                            px: {xs: 2, md: 6},
                            py: {xs: 2, md: 3},
                        }}
                    >
                        <Box sx={{m: 'auto'}}>

                            <Breadcrumbs
                                size="sm"
                                aria-label="breadcrumbs"
                                separator={<ChevronRightRoundedIcon fontSize="small"/>}
                                sx={{pl: 0}}
                            >
                                <Link
                                    to={"/"}
                                    color="neutral"
                                    aria-label="Home"
                                >
                                    <HomeRoundedIcon/>
                                </Link>
                                <Link
                                    to={""}
                                    color="neutral"
                                    aria-label="Home"
                                    style={{textDecoration: "none"}}
                                >
                                    <Typography color="neutral" fontWeight={500} fontSize={12}>
                                        Feux
                                    </Typography>
                                </Link>
                                <Typography color={"primary"} fontSize={12} fontWeight={500}>
                                    {fire.localization.cityName} - {fire.localization.cityZipCode}
                                </Typography>
                            </Breadcrumbs>
                        </Box>

                        <Typography level="h1">
                            {fire.localization.cityName} - {fire.localization.cityZipCode}
                        </Typography>
                        <Typography level="title-sm" sx={{
                            marginTop: "0 !important",
                        }}>
                            <ChipByStateFire state={fire.state} />
                        </Typography>
                        <Typography level="title-sm" sx={{
                            marginTop: "0 !important"
                        }}>
                            {fire.localization.address} - <Link to={"/maps"}>Voir sur la carte</Link>
                        </Typography>
                        <Typography level="title-sm" sx={{
                            marginTop: "0 !important"
                        }}>
                            {formattedDate}
                        </Typography>
                        <Typography level="title-sm" sx={{
                            marginTop: "0 !important"
                        }}>
                            Dernière mise à jour {formattedDateUpdated}
                        </Typography>
                        <Timeline items={fire.historizes} />
                    </Stack>
                </Layout.Main>
                <Layout.Footer>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
                            gap: 2,
                        }}
                    >
                        <FooterNav/>
                    </Box>
                </Layout.Footer>
            </Layout.Root>
        </CssVarsProvider>)
}

export default ShowFire