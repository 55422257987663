import List from "@mui/joy/List"
import React from "react";
import ListItem from "@mui/joy/ListItem";
import {ListItemDecorator} from "@mui/joy";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import NewsPaperRoundedIcon from "@mui/icons-material/Newspaper";
import MapRoundedIcon from "@mui/icons-material/MapRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import {useLocation, useNavigate} from "react-router";

const Navigation = () => {
    const navigate = useNavigate();
//assigning location variable
    const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");

    return (
      <List size="sm" sx={{ '--ListItem-radius': '8px', '--List-gap': '4px' }}>
          <ListItem nested>
              <List
                  aria-labelledby="nav-list-browse"
                  sx={{
                      '& .JoyListItemButton-root': { p: '8px' },
                  }}
              >
                  <ListItem onClick={() => {navigate("/")}}>
                      <ListItemButton selected={splitLocation[1] === ""}>
                          <ListItemDecorator>
                              <HomeRoundedIcon fontSize="small" />
                          </ListItemDecorator>
                          <ListItemContent>Accueil</ListItemContent>
                      </ListItemButton>
                  </ListItem>
                  <ListItem onClick={() => {navigate("/news")}}>
                      <ListItemButton selected={splitLocation[1] === "news"}>
                          <ListItemDecorator>
                              <NewsPaperRoundedIcon fontSize="small" />
                          </ListItemDecorator>
                          <ListItemContent>Actualités</ListItemContent>
                      </ListItemButton>
                  </ListItem>
                  <ListItem onClick={() => {navigate("/maps")}}>
                      <ListItemButton selected={splitLocation[1] === "maps"}>
                          <ListItemDecorator>
                              <MapRoundedIcon fontSize="small" />
                          </ListItemDecorator>
                          <ListItemContent>Carte</ListItemContent>
                      </ListItemButton>
                  </ListItem>
              </List>
          </ListItem>
      </List>
  )
}

export default Navigation;