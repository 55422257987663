import React from "react";
import {HistorizeInterface} from "../../interface/FireInterface";
import moment from "moment";


interface TimelineProps {
    items: HistorizeInterface[];
}

const TimelineItem: React.FC<HistorizeInterface> = ({type, message, createdAt}) => {

    const customFormat = "DD/MM/YYYY [à] HH:mm";
    const formattedDate = moment(new Date(createdAt)).format(customFormat);
    return (
        <div style={styles.timelineItem}>
            <div style={styles.timelineContent}>
                <h3 style={styles.type}>{type}</h3>
                <p style={styles.date}>{formattedDate}</p>
                <p style={styles.message}>{message}</p>
            </div>
        </div>
    );
};

const Timeline: React.FC<TimelineProps> = ({items}) => {
    return (
        <div style={styles.timeline}>
            {items.map((item) => (
                <TimelineItem
                    key={item.id}
                    type={item.type}
                    message={item.message}
                    createdAt={item.createdAt}
                    id={item.id}/>
            ))}
        </div>
    );
};
// Quelques styles inline pour que ce soit simple
const styles = {
    timeline: {
        position: "relative" as "relative",
        padding: "0",
        listStyleType: "none" as "none",
        marginTop: 0
    },
    timelineItem: {
        margin: "20px 0",
        position: "relative" as "relative",
    },
    timelineContent: {
        padding: "10px 20px",
        background: "#f5f5f5",
        borderRadius: "8px",
        position: "relative" as "relative",
    },
    type: {
        margin: 0,
        fontSize: "18px",
        fontWeight: "bold" as "bold",
    },
    date: {
        margin: "5px 0 10px 0",
        fontSize: "14px",
        color: "#999",
    },
    message: {
        margin: 0,
        fontSize: "16px",
    },
};


export default Timeline;
