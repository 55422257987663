import Card from "@mui/joy/Card";
import CardOverflow from "@mui/joy/CardOverflow";
import AspectRatio from "@mui/joy/AspectRatio";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import Divider from "@mui/joy/Divider";
import {Grid} from "@mui/joy";
import * as React from "react";
import {InterfacePost} from "../../interface/InterfacePost";
import {Link} from "react-router-dom";
import moment from "moment";

interface Props {
    item: InterfacePost,
    key: number
}

const CardNewsDesktop: React.FC<Props> = ({item, key}) => {
    const customFormat = "DD/MM/YYYY [à] HH:mm";
    const formattedDate = moment(new Date(item.createdAt)).format(customFormat);

    let stringClean = item.description.replace(/<[^>]*>?/gm, '');
    let stringSubString100 = stringClean.substring(0 , 120);
    return (
        <Grid
            xs={12}
            md={6}
            lg={6}
            sx={{display: {xs: 'none', sm: 'none', md: 'block', lg: 'block'}}}
            key={item._id}
        >
            <Card variant="outlined">
                <CardOverflow>
                    <AspectRatio ratio="2">
                        <img
                            src={item.image}
                            srcSet={item.image}
                        />
                    </AspectRatio>
                </CardOverflow>
                <CardContent>
                    <Typography level="title-md">{item.title}</Typography>
                    <Typography level="body-sm">{stringSubString100}...</Typography>
                </CardContent>
                <CardOverflow variant="soft" sx={{bgcolor: 'background.level1'}}>
                    <Divider inset="context"/>
                    <CardContent orientation="horizontal">
                        <Link to={"/news/" + item._id + "/" + item.slug + ""} style={{textDecoration: "none"}}>
                            <Typography level="body-xs" fontWeight="md" textColor="text.secondary">
                                Voir plus
                            </Typography>
                        </Link>

                        <Divider orientation="vertical"/>
                        <Typography level="body-xs" fontWeight="md" textColor="text.secondary">
                            {formattedDate}
                        </Typography>
                    </CardContent>
                </CardOverflow>
            </Card>
        </Grid>
    )
}

export default CardNewsDesktop;