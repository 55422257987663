import * as React from 'react';
import { useColorScheme } from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Drawer from '@mui/joy/Drawer';
import ModalClose from '@mui/joy/ModalClose';
import DialogTitle from '@mui/joy/DialogTitle';

import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import {useLocation, useNavigate} from "react-router";
import Navigation from "../Navigation/Navigation";



function ColorSchemeToggle() {
    const { mode, setMode } = useColorScheme();
    const [mounted, setMounted] = React.useState(false);
    React.useEffect(() => {
        setMounted(true);
    }, []);
    if (!mounted) {
        return <IconButton size="sm" variant="outlined" color="primary" />;
    }
    return (
        <Tooltip title="Change theme" variant="outlined">
            <IconButton
                id="toggle-mode"
                size="sm"
                variant="plain"
                color="neutral"
                sx={{ alignSelf: 'center' }}
                onClick={() => {
                    if (mode === 'light') {
                        setMode('dark');
                    } else {
                        setMode('light');
                    }
                }}
            >
                {mode === 'light' ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
            </IconButton>
        </Tooltip>
    );
}

export default function HeaderNav() {
    const [open, setOpen] = React.useState(false);
    //assigning location variable
    const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");

    const navigate = useNavigate();
    return (
        <Box
            sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'space-between',
            }}
        >
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                sx={{ display: { xs: 'none', sm: 'flex' } }}
            >
                <IconButton
                    onClick={() => {navigate("/")}}
                    size="md"
                    variant={splitLocation[1] === "" ? "soft" : "plain"}
                    color={splitLocation[1] === "" ? "primary" : "neutral"}
                    sx={{
                        display: { xs: 'none', sm: 'inline-flex' },
                        borderRadius: '50%',
                    }}
                >
                    <HomeRoundedIcon />
                </IconButton>
                <Button
                    onClick={() => {navigate("/news")}}
                    variant={splitLocation[1] === "news" ? "soft" : "plain"}
                    color={splitLocation[1] === "news" ? "primary" : "neutral"}
                    size="sm"
                    sx={{ alignSelf: 'center' }}


                >
                    Actualités
                </Button>
                <Button
                    onClick={() => {navigate("/maps")}}
                    variant={splitLocation[1] === "maps" ? "soft" : "plain"}
                    color={splitLocation[1] === "maps" ? "primary" : "neutral"}
                    size="sm"
                    sx={{ alignSelf: 'center' }}
                >
                    Carte
                </Button>
            </Stack>
            <Box sx={{ display: { xs: 'inline-flex', sm: 'none' } }}>
                <IconButton variant="plain" color="neutral" onClick={() => setOpen(true)}>
                    <MenuRoundedIcon />
                </IconButton>
                <Drawer
                    sx={{ display: { xs: 'inline-flex', sm: 'none' } }}
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    <ModalClose />
                    <DialogTitle>Fire Alert Forest.</DialogTitle>
                    <Box sx={{ px: 1 }}>
                        <Navigation />
                    </Box>
                </Drawer>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1.5,
                    alignItems: 'center',
                }}
            >

                <ColorSchemeToggle />
            </Box>
        </Box>
    );
}