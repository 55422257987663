import Chip from "@mui/joy/Chip";

interface ChipByStateProps {
    state: boolean,
    onClick?: () => void
}

export const ChipByStatePost: React.FC<ChipByStateProps> = ({state, onClick = undefined}) => {
    return (
        <Chip
            variant="soft"
            size="sm"
            color={state ? "success" : "warning"}
            onClick={onClick}
        >
            {state ? "Publié" : "Brouillon"}

        </Chip>
    )
}
