import CssBaseline from "@mui/joy/CssBaseline";
import Layout from "../../Component/Layout/Layout";
import HeaderNav from "../../Component/Header/HeaderNav";
import Typography from "@mui/joy/Typography";
import Box from "@mui/joy/Box";
import FooterNav from "../../Component/FooterNav/FooterNav";
import {CssVarsProvider} from "@mui/joy/styles";
import * as React from "react";

const LegalNotice = () => {
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline/>
            <Layout.Root
                sx={{
                    gridTemplateColumns: {
                        xs: '1fr',
                        md: '1fr',
                    },
                    ...(drawerOpen && {
                        height: '100vh',
                        overflow: 'hidden',
                    }),
                }}
            >
                <Layout.Header>
                    <HeaderNav/>
                </Layout.Header>
                <Layout.Main>
                    <Typography level="h2" component="h1">
                        Mentions légale
                    </Typography>
                    <Typography mt={2} mb={2}>
                        <Typography mt={2} mb={2} level="h4">1. Éditeur du site :</Typography><br/>
                        Feux de foret VAR 83 <br/>
                        firealertforest@gmail.com <br/>


                        <Typography mt={2} mb={2} level="h4">2. Hébergeur :</Typography><br/>

                        <b>Online SAS</b><br/>
                        Adresse : 8 rue de la Ville l'Évêque, 75008 Paris, France<br/>
                        Téléphone : +33 (0)1 84 13 00 00<br/>
                        Site Web : https://www.online.net<br/>

                        <Typography mt={2} mb={2} level="h4">3. Collecte de données personnelles :</Typography><br/>

                        Nous pouvons collecter des données personnelles dans le cadre de l'utilisation de ce site. Ces
                        informations seront traitées conformément à notre politique de confidentialité. <br/>

                        <Typography mt={2} mb={2} level="h4">4. Cookies :</Typography><br/>

                        Ce site utilise des cookies pour améliorer l'expérience utilisateur. En continuant à naviguer
                        sur ce site, vous acceptez l'utilisation de cookies.<br/>

                        <Typography mt={2} mb={2} level="h4">5. Responsabilité :</Typography><br/>

                        Nous ne pouvons garantir l'exactitude ou l'exhaustivité des informations fournies sur ce site.
                        L'utilisateur est responsable de l'utilisation de ces informations.<br/>

                        <Typography mt={2} mb={2} level="h4">6. Liens externes :</Typography><br/>

                        Ce site peut contenir des liens vers des sites externes. Nous déclinons toute responsabilité
                        quant au contenu de ces sites.<br/>

                        <Typography mt={2} mb={2} level="h4">7. Modifications des mentions légales :</Typography><br/>

                        Nous nous réservons le droit de modifier les présentes mentions légales à tout moment. Les
                        utilisateurs sont invités à les consulter régulièrement.<br/>
                    </Typography>
                </Layout.Main>
                <Layout.Footer>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
                            gap: 2,
                        }}
                    >
                        <FooterNav/>
                    </Box>
                </Layout.Footer>
            </Layout.Root>
        </CssVarsProvider>
    )
}

export default LegalNotice;