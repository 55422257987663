import * as React from "react";
import {CssVarsProvider} from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import Layout from "../../Component/Layout/Layout";
import HeaderNav from "../../Component/Header/HeaderNav";
import Typography from "@mui/joy/Typography";
import Box from "@mui/joy/Box";
import FooterNav from "../../Component/FooterNav/FooterNav";

const PrivacyPolicy = () => {
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline/>
            <Layout.Root
                sx={{
                    gridTemplateColumns: {
                        xs: '1fr',
                        md: '1fr',
                    },
                    ...(drawerOpen && {
                        height: '100vh',
                        overflow: 'hidden',
                    }),
                }}
            >
                <Layout.Header>
                    <HeaderNav/>
                </Layout.Header>
                <Layout.Main>
                    <Typography level="h2" component="h1">
                        Politique de confidentialité
                    </Typography>
                    <Typography mt={2} mb={2}>
                        Dernière mise à jour : 28/01/2024<br/>

                        Nous vous remercions de consulter notre politique de confidentialité. La protection de vos
                        informations personnelles est une priorité pour nous. Cette politique explique comment nous
                        collectons, utilisons et protégeons vos données lorsque vous utilisez notre site web ou nos
                        services.<br/>

                        <Typography mt={2} mb={2} level="h4">1. Protection des informations :</Typography><br/>

                        Nous mettons en place des mesures de sécurité pour protéger vos informations contre tout accès
                        non autorisé ou divulgation. Cependant, aucun système de transmission ou de stockage de données
                        n'est totalement sécurisé.<br/>

                        <Typography mt={2} mb={2} level="h4">2. Partage des informations :</Typography><br/>

                        Nous ne vendons, ne louons ni ne partageons vos informations personnelles avec des tiers.<br/>

                        <Typography mt={2} mb={2} level="h4">3. Cookies :</Typography><br/>

                        Nous utilisons des cookies pour analyser les tendances et faciliter la diffusion de publicités
                        personnalisées. Les cookies nous aident à comprendre comment les utilisateurs interagissent avec
                        notre site, à améliorer leur expérience, et à fournir des annonces qui pourraient être
                        pertinentes pour eux.<br/>

                        Vous avez la possibilité de contrôler les cookies via les paramètres de votre navigateur. En
                        modifiant ces paramètres, vous pouvez choisir d'accepter, de rejeter ou de supprimer les
                        cookies. Veuillez noter que la désactivation des cookies peut affecter certaines fonctionnalités
                        de notre site.<br/>


                        <Typography mt={2} mb={2} level="h4">4. Modifications de la politique de confidentialité
                            :</Typography><br/>

                        Nous nous réservons le droit de mettre à jour cette politique de confidentialité à tout moment.
                        Les modifications prendront effet dès leur publication sur cette page.<br/>

                        <Typography mt={2} mb={2} level="h4">5. Contact :</Typography><br/>

                        Si vous avez des questions concernant cette politique de confidentialité, veuillez nous
                        contacter à firealertforest@gmail.com.<br/>
                    </Typography>
                </Layout.Main>
                <Layout.Footer>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
                            gap: 2,
                        }}
                    >
                        <FooterNav/>
                    </Box>
                </Layout.Footer>
            </Layout.Root>
        </CssVarsProvider>
    )
}

export default PrivacyPolicy;