import * as React from 'react';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import IconButton from "@mui/joy/IconButton";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import Button from "@mui/joy/Button";
import {useNavigate} from "react-router";


export default function FooterNav() {
    const navigate = useNavigate();
    return (
        <Box
            sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'space-between',
            }}
        >
            <Stack
                direction="row"
                spacing={1}
                sx={{display: {xs: 'flex', sm: 'flex'}}}
            >
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    sx={{display: {xs: 'flex', sm: 'flex'}}}
                >
                    <Button
                        variant={"plain"}
                        color={"neutral"}
                        size="sm"
                        sx={{alignSelf: 'center'}}
                        onClick={() => {navigate("/legal-notice")}}
                    >
                        Mentions légales
                    </Button>
                    <Button
                        variant={"plain"}
                        color={"neutral"}
                        size="sm"
                        sx={{alignSelf: 'center'}}
                        onClick={() => {navigate("/privacy-policy")}}
                    >
                        Politique de confidentialité
                    </Button>
                    <Button
                        variant={"plain"}
                        color={"neutral"}
                        size="sm"
                        sx={{alignSelf: 'center', display: {xs: "none"}}}
                    >
                        Nous contacter
                    </Button>
                </Stack>
            </Stack>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1.5,
                    marginLeft: 'auto',  // Utilisation de margin-left: auto
                }}
            >
                <IconButton
                    size="sm"
                    sx={{
                        display: {xs: 'inline-block', sm: 'inline-flex'},
                        borderRadius: '50%',
                    }}
                    onClick={() => {window.open('https://www.facebook.com/fdf83var')}}
                >
                    <FacebookRoundedIcon/>
                </IconButton>
            </Box>
        </Box>
    );
}
