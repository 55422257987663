import React, { useState, useRef } from "react";
import axios, {AxiosError} from "axios";
import { useAuth } from "../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import {postAPI} from "../../../Utils/api";

const FormLogin: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const { login } = useAuth();
    const navigate = useNavigate();
    const formRef = useRef<HTMLFormElement>(null);  // Utilisation de useRef pour le formulaire

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);

        const isEmailInvalid = email.trim() === "";
        const isPasswordInvalid = password.length < 1;

        if (isEmailInvalid || isPasswordInvalid) {
            setError("Please fill in all fields correctly.");
            return;
        }

        setLoading(true);

        try {
            const response = await postAPI(`login`, { email, password }, null);

            if (response.data.success) {
                login(response.data.token);  // Appelle le contexte avec le token
            } else {
                setError(response.data.message);  // Gère les erreurs de l'API
            }
        } catch (err: any) {
            if(err.response.status){
                setError('Invalid credentials');
            }else{
                setError('An error occurred. Please try again.');
            }

        } finally {
            setLoading(false);
        }
    };

    return (
        <form>
            <FormControl required>
                <FormLabel>E-mail</FormLabel>
                <Input
                    type="email"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                />
            </FormControl>
            <FormControl required>
                <FormLabel>Mot de passe</FormLabel>
                <Input
                    type="password"
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                />
            </FormControl>
            <Stack gap={4} sx={{mt: 2}}>
                <Button fullWidth onClick={handleLogin}>
                    Connexion à l'administration
                </Button>
            </Stack>
        </form>
    );
}

export default FormLogin;
