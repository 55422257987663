import logoFDF from "../../assets/Image/logo.jpg";
import React from "react";

interface ImageProps {
    width: string
}

export const LogoFDF: React.FC<ImageProps> = ({width}) => {
    return (
        <>
            <img srcSet={logoFDF} style={{
                width: width
            }} alt={"Feux de forêt 83 - VAR "} />
        </>
    )
}

export default LogoFDF;