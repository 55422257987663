import axios from 'axios';
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import {getAPI} from "../Utils/api";

interface UserInfo {
    id: string;
    email: string;
    username: string;
    role: string;
}

interface AuthContextType {
    isAuthenticated: boolean;
    token: string | null;
    userInfo: UserInfo | null;
    login: (token: string) => void;
    logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [token, setToken] = useState<string | null>(null);
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [loading, setLoading] = useState<boolean>(true); // Ajoutez un état de chargement

    useEffect(() => {
        const savedToken = localStorage.getItem('token');
        if (savedToken) {
            setToken(savedToken);
            fetchUserInfo(savedToken);  // Récupère les infos de l'utilisateur si un token est trouvé
        } else {
            setLoading(false); // Terminer le chargement si aucun token n'est trouvé
        }
    }, []);

    const login = (token: string) => {
        setIsAuthenticated(true);
        setToken(token);
        localStorage.setItem('token', token);
        fetchUserInfo(token);  // Récupère les infos de l'utilisateur après la connexion
    };

    const logout = () => {
        setIsAuthenticated(false);
        setToken(null);
        setUserInfo(null);
        localStorage.removeItem('token');
    };

    const fetchUserInfo = async (token: string) => {
        try {
            const response = await getAPI('user', {}, token);
            if (response.data.success) {
                setUserInfo(response.data.data);
                setIsAuthenticated(true);
            } else {
                logout();
            }
        } catch (error) {
            console.error('Failed to fetch user info', error);
            logout();
        } finally {
            setLoading(false); // Terminer le chargement après la tentative de récupération des infos
        }
    };

    if (loading) {
        return <div>Loading...</div>; // Affiche un état de chargement initial
    }

    return (
        <AuthContext.Provider value={{ isAuthenticated, token, userInfo, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
