import axios, {AxiosError} from "axios";

interface IParams {
    baseUrl: string;
}

const apiUrl = process.env.REACT_APP_API_URL;

const config: IParams = {
    baseUrl: apiUrl || "",
};

const handleError = (error: AxiosError) => {
    return {
        status: error.response?.status || 500,
        data: error.response?.data || {},
    };
};

export const postAPI = async (url: string, data: any, token: string|null): Promise<any> => {
    try {
        const response = await axios.post(
            `${config.baseUrl}/${url}`,
            data,
            {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                }
                // withCredentials: true,
            }
        );

        return {
            status: response.status,
            data: response.data,
        };
    } catch (error: any) {
        return handleError(error);
    }
};

export const getAPI = async (url: string, params?: any, token?: string): Promise<any> => {
    try {
        const response = await axios.get(`${config.baseUrl}/${url}`, {
            // withCredentials: true,
            params: params,
            headers: {
                Authorization: token ? `Bearer ${token}` : '',
            }
        });

        return {
            status: response.status,
            data: response.data,
        };
    } catch (error: any) {
        return handleError(error);
    }
};


export const putAPI = async (url: string, data: any, token: string|null): Promise<any> => {

    try {
        const response = await axios.put(
            `${config.baseUrl}/${url}`,
            data,
            {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                }
                // withCredentials: true,
            }
        );

        return {
            status: response.status,
            data: response.data,
        };
    } catch (error: any) {
        return handleError(error);
    }
};